<template>
  <v-card :elevation="elevation" v-if="contact">
    <v-card-title v-if="showTitle">{{ $t("label.info.contact") }}</v-card-title>
    <v-card-text :class="noGutters ? 'pa-0' : ''">
      <v-list dense>
        <v-list-item
          v-if="contact.person"
          :aria-label="$t('label.contact.person')"
        >
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <v-list-item-content>{{ contact.person }}</v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="contact.phone"
          :aria-label="$t('label.contact.phone')"
        >
          <v-list-item-icon><v-icon>mdi-phone</v-icon></v-list-item-icon>
          <v-list-item-content>
            <a :href="'tel:' + contact.phone">{{ contact.phone }}</a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="contact.email"
          :aria-label="$t('label.contact.email')"
        >
          <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
          <v-list-item-content>
            <a :href="'mailto:' + contact.email">{{ contact.email }}</a>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="contact.form_url"
          :aria-label="$t('label.contact.url')"
        >
          <v-list-item-icon><v-icon>mdi-web</v-icon></v-list-item-icon>
          <v-list-item-content>
            <a :href="contact.form_url" target="_blank">{{
              $t("label.contact.url")
            }}</a>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ContactWidget",

  props: {
    contact: { type: Object, required: true },
    showTitle: { default: false, type: Boolean },
    elevation: { type: Number, default: 2 },
    noGutters: { type: Boolean, default: false },
  },
};
</script>

<style scoped></style>
