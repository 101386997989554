<template>
  <v-container
    v-if="dataHost"
    fluid
    class="my-12 px-2 px-sm-4 px-md-6"
    style="max-width: 1200px"
  >
    <v-row>
      <v-col>
        <v-card elevation="4">
          <v-card-text>
            <v-row no-gutters>
              <v-col class="px-4 py-2">
                <h2 class="text-h4">{{ dataHost.name }}</h2>
                <h3 v-if="dataHost.abbrev" class="text-h5">
                  {{ dataHost.abbrev }}
                </h3>
              </v-col>
            </v-row>

            <v-row no-gutters>
              <v-col cols="auto">
                <AddressWidget :source="dataHost" :elevation="0" />
              </v-col>
              <v-col cols="auto" v-if="dataHost.contact">
                <ContactWidget :contact="dataHost.contact" :elevation="0" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3 class="display-5 ml-2">Hosted platforms</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <PlatformList :platforms="platforms" hide-contacts />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3 class="display-5 d-inline ml-2">Audits</h3>
      </v-col>
      <v-col cols="auto">
        <span class="mr-2">
          <span class="font-weight-light mr-2">current status:</span>
          <AuditStatus :status="dataHost.audit_status" />
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <AuditList :audits="audits" :current-audit-id="currentAuditId" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <h3 class="display-5 d-inline ml-2">Audited reports</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <div v-for="release in counterReleases" :key="release.id" class="mb-6">
              <h4 class="pb-3">COUNTER {{ release.number }}</h4>
              <table class="reports">
                <tr
                  v-for="reportType in release.report_types"
                  :key="reportType.id"
                >
                  <th>{{ reportType.name }}</th>
                  <td>
                    <ReportTag
                      v-for="report in reportType.reports"
                      :report="report"
                      :key="report.report_id"
                      :active="reportTypeCodes.has(`${release.number}|${report.report_id}`)"
                    />
                  </td>
                </tr>
              </table>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CounterAPI from "../lib/api";
import AddressWidget from "@/components/AddressWidget.vue";
import ContactWidget from "@/components/ContactWidget.vue";
import PlatformList from "./PlatformList";
import AuditList from "./AuditList";
import AuditStatus from "./AuditStatus";
import ReportTag from "./ReportTag.vue";

export default {
  name: "UsageDataHostDetail",

  components: {
    ReportTag,
    AuditStatus,
    AuditList,
    PlatformList,
    ContactWidget,
    AddressWidget,
  },

  props: {
    dataHostId: { required: true, type: String },
  },

  data() {
    return {
      dataHost: null,
      platforms: [],
      counterReleases: [],
      loading: false,
      loadingReports: false,
      api: new CounterAPI(process.env.COUNTER_API_ROOT),
    };
  },

  computed: {
    audits() {
      return this.dataHost?.audits ?? [];
    },
    currentAuditId() {
      return this.dataHost?.last_finished_audit?.id ?? null;
    },
    reportTypeCodes() {
      if (this.dataHost)
        return new Set(
          this.dataHost.audited_reports.map((report) => `${report.counter_release}|${report.report_id}`)
        );
      return new Set();
    },
  },

  methods: {
    async getData() {
      this.loading = true;
      try {
        this.dataHost = await this.api.getOneUsageDataHost(this.dataHostId);
        this.platforms = await this.api.getPlatformsForUsageDataHost(
          this.dataHostId
        );
      } finally {
        this.loading = false;
      }
    },
    async getReports() {
      this.loadingReports = true;
      try {
        this.counterReleases = await this.api.getReports();
        // sort higher numbers last
        this.counterReleases.sort((a, b) => a.number.localeCompare(b.number));
      } finally {
        this.loadingReports = false;
      }
    },
  },

  mounted() {
    this.getData();
    this.getReports();
  },
};
</script>

<style scoped lang="scss">
table.reports {
  th {
    text-align: right;
    font-weight: 300;
    padding-right: 1rem;
  }
}
</style>
