<template>
  <UsageDataHostDetail :data-host-id="dataHostId" />
</template>

<script>
import UsageDataHostDetail from "../components/UsageDataHostDetail";
export default {
  name: "UsageDataHostDetailPage",
  components: { UsageDataHostDetail },

  props: {
    dataHostId: { required: true, type: String },
  },
};
</script>

<style scoped></style>
