<template>
  <span>
    <v-icon v-if="icon" :color="color" small>{{ icon }}</v-icon>
    {{ text }}
  </span>
</template>
<script>
export default {
  name: "AuditResult",

  props: {
    result: { required: true },
  },

  computed: {
    color() {
      switch (this.result) {
        case "F":
          return "red";
        case "P":
          return "green";
        case "C":
          return "green";
        default:
          return "grey";
      }
    },
    text() {
      switch (this.result) {
        case "F":
          return this.$t("label.audit.failed");
        case "P":
          return this.$t("label.audit.passed");
        case "C":
          return this.$t("label.audit.passed");
        default:
          return "";
      }
    },
    icon() {
      switch (this.result) {
        case "F":
          return "mdi-alert-circle-outline";
        case "P":
          return "mdi-check-circle-outline";
        case "C":
          return "mdi-check-circle-outline";
        default:
          return "";
      }
    },
  },
};
</script>
