<template>
  <v-tooltip bottom v-if="!hidden">
    <template #activator="{ on }">
      <v-chip
        v-on="on"
        :small="small"
        :large="large"
        label
        class="mr-2 my-1"
        :class="active ? 'font-weight-bold' : ''"
        :color="active ? 'primary' : 'grey lighten-3'"
        :text-color="active ? 'white' : 'grey darken-2'"
      >
        <v-icon v-if="!hideMark" class="mr-1"
          >{{ active ? "mdi-check" : "mdi-close" }}
        </v-icon>
        <span class="sr-only">
          {{ $t(active ? "aria.active" : "aria.inactive") }}
        </span>
        {{ report.report_id }}
      </v-chip>
    </template>
    <div>
      <div class="font-weight-bold">{{ report.report_name }}</div>
      <div v-if="report.description">{{ report.description }}</div>
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: "ReportTag",

  props: {
    report: { type: Object, required: true },
    small: { type: Boolean, default: false },
    large: { type: Boolean, default: false },
    active: { type: Boolean, default: true },
    hidden: { type: Boolean, default: false },
    hideMark: { type: Boolean, default: false },
  },
};
</script>

<style scoped></style>
