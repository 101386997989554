<template>
  <v-card :elevation="elevation">
    <v-card-text>
      <v-list dense>
        <v-list-item
          v-if="source.address"
          :aria-label="$t('label.platform.address')"
        >
          <v-list-item-icon>
            <v-icon>mdi-map-marker-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <address class="text-pre">
              <div>{{ source.address }}</div>
              <!-- prettier-ignore -->
              <div v-if="source.address_country">{{ source.address_country.name }}</div>
            </address>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          v-if="source.website"
          :aria-label="$t('label.platform.website')"
        >
          <v-list-item-icon>
            <v-icon>mdi-web</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <a :href="source.website" target="_blank">{{ source.website }}</a>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "AddressWidget",

  props: {
    source: { required: true, type: Object },
    elevation: { type: Number, default: 2 },
  },
};
</script>
<style scoped>
address {
  font-style: normal;
}
</style>
