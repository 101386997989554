<template>
  <v-data-table
    :items="audits"
    :headers="headers"
    item-key="id"
    :items-per-page="1000"
    hide-default-footer
    :loading="loading"
    sort-by="start_date"
    sort-desc
    :item-class="itemClass"
  >
    <template #item.finished="{ item }">
      <v-icon v-if="item.finished">mdi-check</v-icon>
    </template>

    <template #item.locked="{ item }">
      <v-icon v-if="item.locked">mdi-check</v-icon>
    </template>

    <template #item.finish_date="{ item }">
      <IsoDate :date="item.finish_date" />
    </template>

    <template #item.start_date="{ item }">
      <v-tooltip v-if="item.id === currentAuditId" bottom max-width="600px">
        <template #activator="{ on }">
          <v-icon v-on="on" small class="mr-3">mdi-chevron-right</v-icon>
        </template>
        {{ $t("label.audit.currentAuditTT") }}
      </v-tooltip>
      <IsoDate :date="item.start_date" />
    </template>

    <template #item.result="{ item }">
      <AuditResult :result="item.result" />
    </template>
  </v-data-table>
</template>

<script>
import IsoDate from "./IsoDate";
import AuditResult from "./AuditResult.vue";

export default {
  name: "AuditList",
  components: { AuditResult, IsoDate },
  props: {
    audits: { required: true, type: Array },
    loading: { type: Boolean, default: false },
    currentAuditId: { type: String, default: null, required: false },
  },

  data() {
    return {};
  },

  computed: {
    headers() {
      let ret = [
        {
          text: this.$t("label.audit.startDate"),
          value: "start_date",
          align: "right",
        },
        {
          text: this.$t("label.audit.finishDate"),
          value: "finish_date",
          align: "right",
        },
        {
          text: this.$t("label.audit.finished"),
          value: "finished",
          align: "center",
        },
        {
          text: this.$t("label.audit.locked"),
          value: "locked",
          align: "center",
        },
        {
          text: this.$t("label.audit.result"),
          value: "result",
        },
      ];
      return ret;
    },
  },

  methods: {
    itemClass(item) {
      return item.id === this.currentAuditId ? "audit-current" : "audit-other";
    },
  },
};
</script>

<style lang="scss">
tr.audit-current {
  td,
  th {
    // background-color: #f0f0f0;
    font-weight: bold;
  }
}
tr.audit-other {
  td,
  th {
    color: #777777;
  }
}
</style>
