<template>
  <span>
    <v-icon :color="statusColor(status)" small class="mb-1">{{
      statusIcon(status)
    }}</v-icon>
    {{ status }}</span
  >
</template>
<script>
export default {
  name: "AuditStatus",

  props: {
    status: { type: String, required: true },
  },

  methods: {
    statusIcon(status) {
      if (status === "No audit record") return "mdi-close-circle-outline";
      if (status === "Currently valid audit") return "mdi-check-circle-outline";
      if (status === "Audit expired") return "mdi-arrow-down-circle-outline";
      if (status === "Audit in progress") return "mdi-progress-clock";
      if (status === "Audit failed") return "mdi-alert-circle-outline";
      return "";
    },
    statusColor(status) {
      if (status === "No audit record") return "red";
      if (status === "Currently valid audit") return "green";
      if (status === "Audit expired") return "orange";
      if (status === "Audit in progress") return "blue";
      if (status === "Audit failed") return "red";
      return "";
    },
  },
};
</script>
